$(document).ready(function(e){

    window.moment = require('moment-timezone');
    moment.tz.setDefault('Europe/Berlin');

    $(document).ajaxError(function (event, jqXHR) {
        if (403 === jqXHR.status) {
            $(location).attr('href', '/employee/login');
        }
    });

    const moduleStart = '02.06.2019',
        $parkingCal = $('#parking-calendar'),
        employee = $parkingCal.data('employee'),
        hasParkingLot = $('body.has-parkinglot').length > 0,
        isReservation = $parkingCal.data('reservation');

    let getQueryString = function() {
        let key = false, res = {}, itm = null;
        // get the query string without the ?
        let qs = location.search.substring(1);
        // check for the key as an argument
        if (arguments.length > 0 && arguments[0].length > 1)
            key = arguments[0];
        // make a regex pattern to grab key/value
        let pattern = /([^&=]+)=([^&]*)/g;
        // loop the items in the query string, either
        // find a match to the argument, or build an object
        // with key/value pairs
        while (itm = pattern.exec(qs)) {
            if (key !== false && decodeURIComponent(itm[1]) === key)
                return decodeURIComponent(itm[2]);
            else if (key === false)
                res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
        }

        return key === false ? res : null;
    },
    range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step)),
    weekNum = function (dt)
    {
        const tdt = new Date(dt.valueOf());
        let dayn = (dt.getDay() + 6) % 7;
        tdt.setDate(tdt.getDate() - dayn + 3);
        const firstThursday = tdt.valueOf();
        tdt.setMonth(0, 1);
        if (tdt.getDay() !== 4) {
            tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
        }
        return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    },

    openModal = function(elem, thisDay) {
        let $datepicker = $('.datepicker'),
            nextFriday = moment().add(2, 'w').day(moment().day() > 4 ? 5 : -2),
            today18 = moment(new Date()).startOf('day').set({h: 18}),
            date = moment(new Date()),
            parkingStart = moment(moduleStart, 'DD.MM.YYYY'),
            defaultDate = thisDay === undefined || thisDay === null ? moment(new Date()).add(1, 'd').format('DD.MM.YYYY') : thisDay,
            diff = date.diff(parkingStart, 'days'),
            $form = $('#parkingReservation').find('.parking-reservation-form'),
            $guestForm = $('#parkingReservation').find('.guest-reservation-form');

        if(diff < 0) {
            date = parkingStart;
            nextFriday = moment(date).add(2, 'w').day(date.day() > 4 ? 5 : -2);
        }

        if(today18.isBefore(date, 'hour')) {
            date = date.add(1, 'd');
        }

        elem.modal({
           show: true,
           backdrop: 'static'
        });

        $form.find('#parking_reservation_startDate').val(defaultDate);
        $guestForm.find('#guest_reservation_startDate').val(defaultDate);

        $datepicker.datepicker('destroy');
        $datepicker.datepicker({
            format: 'dd.mm.yyyy',
            autoclose: true,
            clearBtn: true,
            todayBtn: false,
            todayHighlight: true,
            weekStart: 1,
            disableTouchKeyboard: true,
            daysOfWeekDisabled: [0,6],
            startDate: date.format('DD.MM.YYYY'),
            endDate: nextFriday.format('DD.MM.YYYY'),
            setDate: moment(defaultDate, 'DD.MM.YYYY').format('DD.MM.YYYY'),
            defaultViewDate: defaultDate,
            updateViewDate: false
        }).on('changeDate', function(e){
            let $target = $(e.target),
                $form = $target.closest('form');
            if($target.attr('id') === 'parking_reservation_startDate'){
                let startDate = $target.val();
                $form.find("#parking_reservation_endDate").focus();
                $form.find("#parking_reservation_endDate").datepicker('update', '').datepicker('setStartDate', moment(startDate, 'DD.MM.YYYY').format('DD.MM.YYYY')).datepicker('setEndDate');
            }
        }).on('show', function(e) {
            const $target = $(e.target);
            $.ajax({
                url: '/employee/calendar/get-holidays',
                type: 'post',
                dataType: 'json',
                data: {}
            }).done(function(data){
                $.each(data['data'], function(k, v){
                    $target.datepicker('setDatesDisabled', v);
                });
            });
        });
        $form.find('#parking_reservation_endDate').datepicker('setStartDate', moment(defaultDate, 'DD.MM.YYYY').format('DD.MM.YYYY'));
    },

    getReservationModalHTML = function(obj) {

        if(hasParkingLot && !obj.isGuest && !obj.processed && obj.admin === undefined) {
            return;
        }

        const title = obj.isGuest ? "Guest reservation" : "Reservation",
            reservationCls = obj.isGuest ? 'is-guest' : 'is-own';
        let html = "";
        html += "<div class='row reservation " + reservationCls + "' data-id='" + obj.id + "'>";
        html += "<div class='col-12'><p><strong>" + title + "</strong></p></div>";
        if(obj.isGuest) {
            html += "<div class='col-9'><div class='row'><div class='col-12'><span class='fal fa-parking mr-2'></span>" + obj.parkinglot + "</div></div><div class='row'><div class='col-12'><span class='fal fa-comment mr-2'></span>" + obj.comment + "</div></div></div>";
            html += "<div class='col'><div class='d-flex justify-content-end'><a href='#' class='btn btn-sm btn-outline-danger yes-delete-reservation' data-employee='" + employee + "' data-reservation='" + obj.id + "'><span class='fal fa-times-circle'></span></a></div></div><div class='col-12'><hr class='mb-3'></div>";
        } else {
            const byAdmin = obj.admin !== undefined && obj.admin !== null;
            html += "<div class='col-12'>";
            if(byAdmin) {
                html += "<div class='row'><div class='col-12'><span class='fal fa-lock mr-2'></span> blocked by parking admin</div></div>";
                html += "<div class='row'><div class='col-12'><span class='fal fa-comment mr-2'></span>" + obj.comment + "</div></div>";
            } else {
                if(obj.processed) {
                    if(isReservation && !hasParkingLot) {
                        if(obj.occupied) {
                            html += "<div class='row'><div class='col-12'><div class='alert alert-danger'><span class='fal fa-parking-slash mr-2'></span>All parking lots are occupied</div></div></div>";
                        } else {
                            html += "<div class='row'><div class='col-12'><span class='fal fa-parking mr-2'></span>" + obj.parking + "</div></div>";
                        }
                    } else {
                        html += "<div class='row'><div class='col-12'><span class='fal fa-parking mr-2'></span> reserved by " + obj.title + "</div></div>";
                    }
                } else{
                    if(isReservation) {
                        html += "<div class='row'><div class='col-9'><div class='alert alert-warning'>Your reservation has not yet been processed</div></div>";
                        html += "<div class='col'><div class='d-flex justify-content-end'><a href='#' class='btn btn-sm btn-outline-danger yes-delete-reservation' data-employee='" + employee + "' data-reservation='" + obj.id + "'><span class='fal fa-times-circle'></span></a></div></div></div>";
                    }
                }
            }
            html += "<div class='row'><div class='col-12'><hr class='mb-3'></div></div></div>";
        }
        html += "</div>";
        return html;
    },

    getGuestReservationForm = function(date, employee, guestCount, hasOwnReservation) {
        let html = "";
        if(guestCount <= 1) {
            html += "<form class='form d-none' name='guest_reservation' id='guest_reservation'>" +
                "<div class='row'><div class='col-12 mb-2'><span class='text-muted'>Add a new guest reservation for this day.</span></div></div>" +
                "<input type='hidden' name='guest_reservation[startDate]' value='" + date + "'>" +
                "<input type='hidden' name='guest_reservation[guest]' value='1'>" +
                "<input type='hidden' name='guest_reservation[endDate]'>" +
                "<input type='hidden' name='guest_reservation[employeeId]' value='" + employee + "'>" +
                "<div class='form-group mb-2'><label class='required' for='comment'>Comment</label>" +
                "<textarea class='form-control' type='hidden' name='guest_reservation[comment]' id='comment' required='required'></textarea></div>" +
                "<div class='form-group'><button type='submit' id='save_guest_reservation' name='add' class='btn btn-outline-primary'><span class='fal fa-check'></span> Save guest reservation</button><button type='button' id='cancel_guest_reservation' name='cancel' class='btn btn-outline-secondary ml-2'>Cancel</button></div>" +
                "</form>";
        }

        if(!hasOwnReservation && !hasParkingLot) {
            html += "<form class='form d-none' name='parking_reservation' id='parking_reservation'>" +
                "<div class='row'><div class='col-12 mb-2'><span class='text-muted'>Add a new reservation request for you for this day. No more data needed. Please save.</span></div></div>" +
                "<input type='hidden' name='parking_reservation[startDate]' value='" + date + "'>" +
                "<input type='hidden' name='parking_reservation[endDate]'>" +
                "<input type='hidden' name='parking_reservation[employeeId]' value='" + employee + "'>" +
                "<div class='form-group'><button type='submit' id='save_parking_reservation' name='add' class='btn btn-outline-primary'><span class='fal fa-check'></span> Save reservation</button><button type='button' id='cancel_parking_reservation' name='cancel' class='btn btn-outline-secondary ml-2'>Cancel</button></div>" +
                "</form>";
        }

        html += "<div class='row get-form-row'><div class='col'>";
        if(guestCount <= 1) {
            html += "<a href='#' class='btn btn-outline-primary add-guest-reservation-form'><span class='fal fa-plus-circle'></span> Add guest reservation</a>";
        }
        if(!hasOwnReservation && !hasParkingLot) {
            html += "<a href='#' class='btn btn-outline-primary add-parking-reservation-form ml-2'><span class='fal fa-plus-circle'></span> Add reservation</a>";
        }
        html += "</div></div>";

        return html;
    },

    getReservationsByDate = function (date, employee, elem) {

        if(elem === undefined || elem === null) {
            elem = $('.modal');
        }

        const data = new FormData();

        data.append('employee', employee);
        data.append('date', moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD'));

        fetch('/parking/get-by-date', {
            method: 'POST',
            body: data
        })
            .then(response => response.json())
            .then(data => {

                if(data['success'] === false) {
                    return false;
                }
                const reservationNodes = document.querySelectorAll('#deleteReservation .reservation'),
                    reservationWrapper = document.querySelector('#deleteReservation .reservation-list');

                let html = "";
                data['data'].forEach(function(reservation) {
                    let exists = false;
                    reservationNodes.forEach(node => {
                        if(parseInt(node.dataset.id) === reservation.id) {
                            exists = true;
                        }
                    });

                    if(exists) {
                        return false;
                    }

                    reservation.parkinglot = "";
                    reservation.isGuest = reservation.guest > 0 && reservation.guest !== null;
                    if(reservation.nr !== null && reservation.level !== null) {
                        reservation.parkinglot = reservation.nr + "/" + reservation.level;
                    }

                    const reservationHtml = getReservationModalHTML(reservation);
                    html += reservationHtml !== undefined ? reservationHtml : "";
                });

                reservationWrapper.innerHTML = reservationWrapper.innerHTML + html;

                const $reservations = elem.find('.reservation-list'),
                    countGuest = $reservations.find('.is-guest').length,
                    hasOwn = $reservations.find('.is-own').length > 0;
                elem.find('#guest_reservation').remove();
                elem.find('#parking_reservation').remove();
                elem.find('.add-guest-reservation-form').remove();
                elem.find('.add-parking-reservation-form').remove();
                html = getGuestReservationForm(date, employee, countGuest, hasOwn);
                $reservations.append(html);

            });
    },

    openDeleteModal = function(employee, date, events) {

        let elem = $('#deleteReservation');
        if(elem.length > 0) {
            elem.remove();
        }

        let guestCount = 0,
            hasOwnReservation = false;
        let html = "<div id='deleteReservation' class='modal' tabindex='-1' role='dialog' aria-labelledby='deleteReservationTitle' aria-hidden='true' data-date='" + date + "'>" +
            "    <div class='modal-dialog modal-md' role='document'>" +
            "        <div class='modal-content'>" +
            "            <div class='modal-header'>" +
            "                <h5 class='modal-title subline' id='deleteReservationTitle'>Your reservations at " + date + "</h5>" +
            "                <button type='button' class='close' aria-label='Close'>" +
            "                    <span aria-hidden='true' class='fal fa-times'></span>" +
            "                </button>" +
            "            </div>" +
            "            <div class='modal-body'>" +
            "               <div class='container-fluid px-0 reservation-list'>";
        $.each(events, function(k,event){
            if(event.isGuest) {
                guestCount = guestCount + 1;
            } else {
                if(isReservation) {
                    hasOwnReservation = true;
                }
            }
            const reservationHtml = getReservationModalHTML(event);
            html += reservationHtml !== undefined ? reservationHtml : "";
        });

            html += "      </div>";

        html += getGuestReservationForm(date, employee, guestCount, hasOwnReservation);

        html += "       </div>" +
            "       </div>" +
            "   </div>" +
            "</div>";

        const $wrapper = $('body');

        $wrapper.append(html);
        $('#deleteReservation').modal({
            show: true,
            backdrop: 'static'
        });
    },

    openParkingAdminModal = function(dayElem) {
        let $elem = $('#parking-admin-modal');
        if($elem.length > 0) {
            $elem.remove();
        }

        const date = moment(dayElem.data('date'), 'YYYY-MM-DD'),
            $employeeCal = dayElem.closest('.employee-calendar'),
            employee = $employeeCal.data('employee'),
            id = $employeeCal.data('id'),
            data = new FormData();

        data.append('parkingLot', id);
        data.append('date', date.format('YYYY-MM-DD'));

        fetch('/parking/parking-overview/r-exists', {
            method: 'POST',
            body: data
        })
            .then(response => response.json())
            .then(data => {
                if(data['success'] === false || data['data'] === false) {
                    return false;
                }

                const r = data['data'][0],
                    hasReservation = r !== undefined && r !== null,
                    isBlocked = hasReservation && r['blocked'] !== undefined && r['blocked'] === true,
                    isReserved = hasReservation && !isBlocked,
                    isFree = !(isReserved || isBlocked),
                    hasGuest = hasReservation && r['guest'] >= 1,
                    reservation = hasReservation ? r['id'] : 0;

                let dataReserved = '',
                    dataGuest = '',
                    content = "<div class='actions'><hr class='mt-3 mb-3'>",
                    cancelField = "<div class='form-check mb-2'><input type='radio' name='action' class='form-check-input cancel-reservation' value='1' id='cancelReservation'> <label for='cancelReservation' class='form-check-label'>Cancel reservation</label><small class='form-text text-muted'>The employee get an email about the cancelation of the reservation. After that the parking lot is free again for this day.</small></div>",
                    blockedCheck = isBlocked ? " checked='checked'" : "",
                    blockFieldType = isReserved ? "radio" : "checkbox",
                    blockField = "<div class='form-check mb-3'><input type='" + blockFieldType + "' name='action' class='form-check-input block-parkinglot' value='2' id='blockParkingLot'" + blockedCheck + "> <label for='blockParkingLot' class='form-check-label'>Block parking lot</label><small class='form-text text-muted'>Block this parking lot for customers or visitors. If the parking lot is already reserved by an employee the employee get an email.</small></div>";

                if(hasGuest) {
                    blockField += "<div class='alert alert-warning'><input type='hidden' name='guest' class='form-check-input block-parkinglot' value='" + r['guest'] + "'>Guest reservation<br><small>This reservation is a guest reservation and reserved by your colleague <nobr><b>" + r['employee'] + "</b></nobr>.</small></div>";
                    dataGuest = r['guest'];
                }

                if(isReserved) {
                    content += "<span class='badge outlined text-muted mb-3'><i class='fal fa-user'></i> reserved</span>" +
                        cancelField + blockField;
                    dataReserved = " data-reserved='1'";
                } else if(isBlocked) {
                    if(hasGuest) {
                        content += "<span class='badge outlined mb-3 mr-1'><i class='fal fa-user-tie'></i> guest</span>";
                    }
                    content += "<span class='badge outlined text-muted mb-3'><i class='fal fa-lock'></i> blocked</span>";
                    content += blockField;
                } else if(isFree) {
                    content += "<span class='badge outlined text-muted mb-3' style='background-color:#CCFFCC;'>free</span>" +
                        blockField;
                }

                content += "<hr><a href='#' class='btn btn-outline-primary pp-admin-save-parkinglot' data-id='" + id + "' data-employee='" + employee + "' data-date='" + date.format('YYYY-MM-DD') + "'" + " data-reservation='" + reservation + "'" + dataReserved + " data-guest='" + dataGuest + "'><i class='fal fa-check'></i> Save</a> <a href='#' class='btn btn-outline-secondary close-modal'>Cancel</a>" +
                    "</div>";

                let html = "<div id='parking-admin-modal' class='modal' tabindex='-1' role='dialog' aria-labelledby='parkingAdminModalTitle' aria-hidden='true'>" +
                    "    <div class='modal-dialog modal-md' role='document'>" +
                    "        <div class='modal-content'>" +
                    "            <div class='modal-header'>" +
                    "                <h5 class='modal-title subline' id='parkingAdminModalTitle'>Manage parking lot</h5>" +
                    "                <button type='button' class='close' aria-label='Close'>" +
                    "                    <span aria-hidden='true' class='fal fa-times'></span>" +
                    "                </button>" +
                    "            </div>" +
                    "            <div class='modal-body'>" +
                    "                <div class='container-fluid px-0'>" +
                    "                   <div><hr class='mb-3 mt-0'><i class='fal fa-calendar-alt'></i> " + date.format('DD.MM.YYYY') +
                    "                   " + content +
                    "                   </div>" +
                    "                </div>" +
                    "            </div>" +
                    "        </div>" +
                    "    </div>" +
                    "</div>";


                let $wrapper = $('body');
                $wrapper.append(html);
                $('#parking-admin-modal').modal({
                    show: true,
                    backdrop: 'static'
                });
            });
    },

    closeModal = function(){
        $('.modal').each(function () {
            $(this).find('.form-error').empty();
            $(this).modal('hide');
        });
    },
    // open tooltip
    openTooltip = function(elem, title, mouseevent){
        if($('.tooltip').length > 0 && mouseevent !== true){
            $(elem).tooltip('hide');
            return true;
        }
        $(elem).tooltip({
            title: title,
            html: true,
            trigger: 'manual'
        }).tooltip('show');
    },
    // close tooltip
    closeTooltip = function(elem){
        $(elem).tooltip('hide');
    },
    checkFormInput = function(form, isGuest = false){
        let html = '',
            startVal = isGuest ? form.find('#guest_reservation_startDate').val() : form.find('#parking_reservation_startDate').val(),
            endVal = isGuest ? '' : form.find('#parking_reservation_endDate').val();

        if(startVal === '' || startVal === null || moment(startVal, 'DD.MM.YYYY', true).isValid() === false){
            html += "<p class='mb-1'>Please insert a valid start date <i>(format: dd.mm.yyyy)</i>.</p>";
        }
        if(endVal.length > 0 && moment(endVal, 'DD.MM.YYYY', true).isValid() === false){
            html += "<p class='mb-1'>Please insert a valid end date <i>(format: dd.mm.yyyy)</i>.</p>";
        }
        if(moment(endVal, 'DD.MM.YYYY', true).isValid() && moment(startVal, 'DD.MM.YYYY', true).isValid() && moment(startVal, 'DD.MM.YYYY').diff(moment(endVal, 'DD.MM.YYYY'), 'days') > 0){
            html += "<p class='mb-1'>The start date can not be before the end date. Please correct your entries.</p>";
        }

        return html;
    },
    $calendar = $('.schedule-parking'),
    getDaysArray = function(year, month) {
        const names = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ];
        let date = new Date(year, month - 1, 1),
            result = [];
        while (date.getMonth() === month - 1) {
            result.push([date.getDate(), names[date.getDay()], moment(date).format('DD.MM.YYYY')]);
            date.setDate(date.getDate() + 1);
        }
        return result;
    },
    getParkingCalendarHeader = function(month){
        let $wrapper = $calendar.find('.calendar-wrapper'),
            $header = $wrapper.find('.calendar-header'),
            today = moment().startOf('day'),
            monthTxt = moment(month).format('MMMM YYYY'),
            html = "<strong class='month-title btn btn-outline-primary btn-sm ml-2' data-month='" + moment(month).format('YYYY-MM-01') + "'>" + monthTxt + "</strong>",
            days = getDaysArray(moment(month).format('YYYY'), moment(month).format('MM'));
        html += "<div class='days d-flex justify-content-start'>";
        $.each(days, function(k, day){
            let thisDate = moment(day[2], 'DD.MM.YYYY'),
                thisDay = thisDate.day(),
                todayCls = thisDate.diff(today, 'days') === 0 ? ' today' : '',
                weekendCls = thisDay === 0 || thisDay === 6 ? ' weekend' : '';
            html += "<div class='day d-flex align-items-center justify-content-center" + todayCls + weekendCls + "'><span>" + day[0] + "</span></div>";
        });
        html += "</div>";
        $header.html(html);
    },
    getParkingCal = function(year, month) {
        let calHtml = "",
            $wrapper = $calendar.find('.calendar-wrapper');
        $.ajax({
            url: '/parking/parking-overview/by-parking-lot',
            type: 'post',
            dataType: 'json',
            data: {year: year, month: month}
        }).done(function(data){
            if(data['success'] === false){
                calHtml += "<div class='alert alert-danger'>Loading calendar failed.</div>";
                $wrapper.find('.calendar').html(calHtml);
                return false;
            }

            let today = moment().startOf('day');
            const allHolidays = data['holidays'];

            $.each(data['data'], function(k, parkinglot){
                let $employeeCal = $('.employee-calendar[data-id="' + k + '"]'),
                    employeeHtml = "",
                    daysInMonth = moment(year + '' + month + '01', 'YYYYMMDD').daysInMonth();
                const attendances = parkinglot['attendance'],
                    reservations = parkinglot['reservation'];
                employeeHtml += "<div class='employee-days d-flex justify-content-start'>";

                for (let d = 1; d <= daysInMonth; d++) {
                    let bgColor = '#fff',
                        icon = '',
                        tooltipCls = '',
                        tooltip = '',
                        cssCls = '',
                        dataAttr = '',
                        thisDate = moment(d + "." + month + "." + year, 'DD.MM.YYYY').startOf('day'),
                        thisDay = thisDate.day(),
                        todayCls = thisDate.diff(today, 'days') === 0 ? ' today' : '',
                        weekendCls = thisDay === 0 || thisDay === 6 ? ' weekend' : '',
                        isHoliday = false,
                        hasReservation = false,
                        hasAtt = false,
                        hasGuest = false;

                    if(allHolidays[parkinglot['location']] !== undefined && allHolidays[parkinglot['location']][thisDate.format('DD.MM.YYYY')] !== undefined) {
                        isHoliday = true;
                        hasReservation = false;
                        bgColor = "#f5f5f5";
                        icon = "<i class='fal fa-star'></i>";
                        tooltipCls = ' is-holiday';
                        tooltip = " date-comment='" + allHolidays[parkinglot['location']][thisDate.format('DD.MM.YYYY')] + "'";
                    }

                    $.each(reservations, function(key, reservation) {
                        const attStart = moment(reservation['startDate']['date']);

                        if(thisDate.diff(attStart, 'days') === 0) {

                            hasReservation = !isHoliday ?? true;
                            hasGuest = reservation['guest'] >= 1;
                            hasAtt = false;

                            if(weekendCls === '' && !isHoliday) {

                                dataAttr += " data-reservation='" + reservation['id'] + "'";
                                if(hasGuest && reservation['parkingLotId'] === k) {
                                    icon = "<i class='fal fa-user-tie'></i>";
                                    bgColor = "#fcc";
                                    cssCls += ' reserved';
                                    tooltipCls = ' has-comment';
                                    tooltip = " data-html='true' data-comment='Guest reservation<hr>Reserved by: " + reservation['employee'] + "<br>Comment: " + reservation['comment'] + "'";
                                }
                                if (reservation['parkingLotId'] === k && reservation['blocked'] !== true) {
                                    icon = "<i class='fal fa-user'></i>";
                                    bgColor = '#fcc';
                                    cssCls += ' reserved';
                                    tooltipCls = ' reserved-by-employee';
                                    tooltip = " data-comment='" + reservation['employee'] + "'";
                                }
                                if (!hasGuest && reservation['blocked'] === true) {
                                    icon = "<i class='fal fa-lock'></i>";
                                    bgColor = '#fcc';
                                    tooltipCls = reservation['comment'] !== null && reservation['comment'] !== '' ? ' has-comment' : '';
                                    tooltip = " data-comment='" + reservation['comment'] + "'";
                                    cssCls += ' blocked';
                                }
                                employeeHtml += "<div class='day d-flex align-items-center justify-content-center grey" + todayCls + weekendCls + tooltipCls + cssCls + "' style='background-color: " + bgColor + ";'" + tooltip + " data-date='" + thisDate.format('YYYY-MM-DD') + "'" + dataAttr + ">" + icon + "</div>";
                                return true;
                            }
                        }
                    });

                    if(!hasReservation) {

                        $.each(attendances, function (key, att) {
                            const attStart = moment(att['startDate']['date']),
                                attEnd = att['endDate'] !== undefined && att['endDate'] !== null ? moment(att['endDate']['date']) : null;

                            if ((attEnd === null && thisDate.diff(attStart, 'days') === 0) || (attEnd !== null && thisDate.isBetween(attStart, attEnd, 'days', '[]'))) {
                                hasAtt = true;
                                if (weekendCls === '' && !isHoliday) {
                                    dataAttr = " data-attendance='" + att['id'] + "'";
                                    icon = '';

                                    if (((att['isPresent'] === null || att['isPresent'] === false) && att['parkingLotFree'] !== false) || (att['isPresent'] === true && att['parkingLotFree'] === true)) {
                                        bgColor = '#cfc';
                                        cssCls += ' free';
                                    } else {
                                        bgColor = '#fcc';
                                        cssCls += ' occupied';
                                    }

                                }


                                employeeHtml += "<div class='day d-flex align-items-center justify-content-center grey" + todayCls + weekendCls + tooltipCls + cssCls + "' style='background-color: " + bgColor + ";'" + tooltip + " data-date='" + thisDate.format('YYYY-MM-DD') + "'" + dataAttr + ">" + icon + "</div>";

                                return true;
                            }
                        });
                    }

                    if(!hasAtt && !hasReservation) {
                        if(weekendCls !== '' && !isHoliday) {
                            icon = '';
                        } else if(!isHoliday) {
                            icon = '<i class="fal fa-question"></i>'
                            bgColor = '#cfc';
                            cssCls = ' free question';
                        }
                        employeeHtml += "<div class='day d-flex align-items-center justify-content-center grey" + todayCls + weekendCls + tooltipCls + cssCls + "' style='background-color: " + bgColor + ";'" + tooltip + " data-date='" + thisDate.format('YYYY-MM-DD') + "'" + dataAttr + ">" + icon + "</div>";
                    }

                }

                employeeHtml += "</div>";
                $employeeCal.html(employeeHtml);
            });
        });
    },
    getCancelOptionFields = function($elem) {
        return true;
        let $modal = $elem.closest('.modal');

        if(!$elem.prop('checked')) {
            if($modal.find('.cancel-option-fields').length > 0) {
                $modal.find('.cancel-option-fields').remove();
            }
            return true;
        }

        let commentField = "<div class='cancel-option-fields'>" +
            "<div class='form-check mb-2 ml-3'><input type='checkbox' name='reserve-again' class='form-check-input reserve-again' value='1' id='findNewParkingLot'> <label for='findNewParkingLot' class='form-check-label'>Reserve</label><small class='form-text text-muted'>The app will search for a free parking lot for the employee.</small></div>" +
            "</div>";
        $elem.closest('.form-check').after(commentField);
    },
    getBlockedCommentField = function($elem) {
        let $modal = $elem.closest('.modal');

        if(!$elem.prop('checked')) {
            if($modal.find('.comment-field').length > 0) {
                $modal.find('.comment-field').remove();
            }
            return true;
        }

        let reservation = $modal.find('.pp-admin-save-parkinglot').data('reservation'),
            comment = '',
            commentField = "<div class='form-group comment-field'>" +
            "    <label for='comment'>Comment</label>" +
            "    <textarea class='form-control block-comment' id='comment' rows='2'>" + comment + "</textarea>" +
            "<small class='form-text text-muted'>Please tell your colleagues why the parking lot is blocked on this day.</small></div>";
        $elem.closest('.form-check').after(commentField);

        if(reservation !== undefined && reservation !== null) {
            $.ajax({
                url: "/parking/parking-overview/get-comment",
                type: 'post',
                async: true,
                dataType: 'json',
                data: {reservation: reservation}
            }).done(function (data) {
                if(data['success'] === true && data['data'] !== false) {
                    comment = data['data'];
                    $('.block-comment').text(comment);
                }
            });
        }
    };


    if($calendar.length > 0) {
        let $wrapper = $calendar.find('.calendar-wrapper'),
            urlMonth = getQueryString('month'),
            month = urlMonth !== undefined && urlMonth !== null ? moment(urlMonth, 'YYYY-MM-DD') : moment();

        if($wrapper.length > 0) {

            $('.employee-wrapper .employee').on('mouseenter mouseleave', function (e) {
                return true;
            });

            $('.employee-calendar').on('mouseenter mouseleave', function (e) {
                return true;
            });

            getParkingCalendarHeader(month);
            getParkingCal(moment(month).format('YYYY'), moment(month).format('MM'));

            $(document).on('click', '.calendar-header .month-title', function(e){
                let $this = $(this),
                    currentDate = $this.data('date');
                //$this.datepicker('destroy');
                $this.datepicker({
                    startView: 1,
                    //defaultViewDate: moment(currentDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    setDate: moment(currentDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                    minViewMode: 1,
                    maxViewMode: 2,
                    format: 'dd.mm.yyyy',
                    autoclose: true,
                    todayBtn: false,
                    todayHighlight: true,
                    disableTouchKeyboard: true,
                    weekStart: 1,
                    zIndexOffset: 2000
                });
                $this.datepicker('show');
                $this.datepicker().on('changeMonth', function(e){
                    let date = moment(e.date);
                    $('.employee-calendar').fadeOut(100, function(){
                        $(this).html('').css({'display': 'flex'});
                    });
                    getParkingCalendarHeader(date);
                    getParkingCal(date.format('YYYY'), date.format('MM'));
                });
            });


            // parking admin actions
            $(document).on('click', '.employee-days .day', function(e) {
                e.preventDefault();
                let $this = $(this),
                    date = moment($this.data('date'), 'YYYY-MM-DD'),
                    now = moment().subtract('1', 'days');

                if(now > date) {
                    return true;
                }

                // weekend
                if($this.hasClass('weekend') || $this.hasClass('occupied')) {
                    return true;
                }

                openParkingAdminModal($this);

            });


            $(document).on('mouseenter mouseleave', '.employee-days .day', function(e) {
                e.preventDefault();
                let $this = $(this);
                if($this.hasClass('has-comment') === false && $this.hasClass('reserved-by-employee') === false) {
                    return true;
                }
                if(e.type === 'mouseenter') {
                    openTooltip($this, $this.data('comment'), true);
                    return true;
                }
                if(e.type === 'mouseleave') {
                    closeTooltip($this);
                    return true;
                }
            });

            // get comment field if option blocked
            // is selected
            $(document).on('click', 'input[name="action"], .block-parkinglot, .cancel-reservation', function(e) {

                let $this = $(this),
                    $modal = $this.closest('.modal'),
                    $blockField = $modal.find('.block-parkinglot'),
                    $cancelField = $modal.find('.cancel-reservation');

                getBlockedCommentField($blockField);
                getCancelOptionFields($cancelField);

            });

            $(document).on('show.bs.modal', '#parking-admin-modal', function(e){
                let $blockField = $(this).find('.block-parkinglot'),
                    $cancelField = $(this).find('.cancel-reservation');

                getBlockedCommentField($blockField);
                getCancelOptionFields($cancelField);
            });


            // Save PP-Admin Action
            $(document).on('click', '.pp-admin-save-parkinglot', function (e){
                e.preventDefault();
                let $this = $(this),
                    $modal = $this.closest('.modal'),
                    $response = $modal.find('.form-response'),
                    $cancel = $modal.find('.cancel-reservation'),
                    $block = $modal.find('.block-parkinglot'),
                    $comment = $modal.find('.block-comment'),
                    cancel = 0,
                    block = 0,
                    comment = '',
                    reserved = $this.data('reserved') === undefined ? 0 : $this.data('reserved'),
                    id = $this.data('id'),
                    employee = $this.data('employee') === undefined ? null : $this.data('employee'),
                    reservation = $this.data('reservation') === undefined ? null : $this.data('reservation'),
                    guest = $this.data('guest') === undefined || $this.data('guest') === '' ? null : $this.data('guest'),
                    date = moment($this.data('date'), 'YYYY-MM-DD');

                $this.addClass('disabled');
                $this.attr('disabled', 'disabled');

                if($response.length > 0) {
                    $response.remove();
                }

                if($cancel !== undefined && $cancel.prop('checked')) {
                    cancel = 1;
                }
                if($block !== undefined && $block.prop('checked')) {
                    block = 1;
                }
                if($comment.length > 0) {
                    comment = $comment.val();
                }

                $.ajax({
                    url: '/parking/parking-overview/pp-admin-action',
                    type: 'post',
                    dataType: 'json',
                    data: {id: id, employee: employee, cancel: cancel, block: block, comment: comment, reserve: reserved, reservation: reservation, guest: guest, date: date.format('YYYY-MM-DD')}
                }).done(function(data) {

                    $this.removeClass('disabled');
                    $this.removeAttr('disabled');

                    if(data['success'] === false || data['data'] === false) {
                        let saveErrorText = '';
                        if (data['data'] === 'duplicate') {
                            saveErrorText = '<br>A reservation exists at this date. Please reload the page.';
                        }
                        $modal.find('.modal-body').prepend(
                            "<div class='alert alert-danger form-response'><i class='fal fa-exclamation-triangle'></i> Saving failed!" + saveErrorText + "</div>"
                        );
                        return false;
                    }

                    if(data['success'] === true && data['data'] === true) {
                        closeModal();
                        getParkingCal(date.format('YYYY'), date.format('MM'));
                    }
                });
            });
        }
    }

    let getParkingLotCalendar = function(events, month, weeks) {

            let nextFriday = moment().add(2, 'w').day(moment().day() > 4 ? 5 : -2),
                today18 = moment(new Date()).startOf('day').hour(17).minute(59),
                parkingStart = moment(moduleStart, 'DD.MM.YYYY'),
                now = moment(new Date()),
                diff = now.diff(parkingStart, 'days');

            if(diff < 0) {
                now = parkingStart;
                nextFriday = moment(now).add(2, 'w').day(moment().day() > 4 ? 5 : -2);
            }

            if(today18.isBefore(now, 'hour')) {
                now = now.add(1, 'd');
            }

            let parkingLotCal = $parkingCal.clndr({
                template: $("#template-parking-lot-calendar").html(),
                startWithMonth: month,
                weekOffset: 1,
                daysOfTheWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                constraints: {
                    startDate: now,
                    endDate: nextFriday
                },
                extras: {
                    options: {
                        show_weekends: false,
                        weekNumbers: weeks,
                    },
                },
                clickEvents: {

                    click: function(target) {
                        if($(target.element).hasClass('inactive') || $(target.element).hasClass('adjacent-month') || $(target.element).hasClass('is-holiday')) {
                            return true;
                        }
                        if ($(target.element).hasClass('event')) {
                            let reservation = $(target.element).data('reservation');
                            openDeleteModal(employee, moment(target.date._i).format('DD.MM.YYYY'), target.events);
                        } else {
                            $.ajax({
                                url: '/parking/get-form',
                                type: 'post',
                                data: {'onlyGuest': true},
                                dataType: 'json'
                            }).done(function (data) {
                                $('body').append(data['response']);
                                openModal($('#parkingReservation'), moment(target.date._i).format('DD.MM.YYYY'));
                            });
                        }
                    },


                    onMonthChange: function (month) {
                        this.removeEvents(function (event) {
                            return true;
                        });
                        const calendarParkingEvents = [];
                        $.ajax({
                            method: "post",
                            dataType: "json",
                            async: true,
                            url: '/parking/calendar/get',
                            data: {employee: employee, reservation: isReservation, month: month.startOf('month').format('YYYY-MM-DD')},
                            success: function (data) {
                                $.each(data['data'], function (key, event) {
                                    let item;

                                    if(key === 'holidays') {
                                        $.each(event, function(k, holiday){
                                            item = {
                                                "date": holiday.date,
                                                "id": holiday.id,
                                                "title": holiday.name,
                                                "comment": holiday.name,
                                                "color": "#f0f0f0",
                                                "isHoliday": holiday.isHoliday
                                            };
                                            calendarParkingEvents.push(item);
                                        });
                                        return true;
                                    }

                                    item = {
                                        "date": event.start,
                                        "title": event.employee,
                                        "color": event.color,
                                        "id": event.id,
                                        "occupied": event.occupied
                                    };
                                    calendarParkingEvents.push(item);
                                });
                                getParkingLotCalendar(calendarParkingEvents, month);
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                            }
                        });
                    }
                },
                doneRendering: function() {
                    let self = this;

                    $(document).on('mouseover touchstart', '.event', function (e) {
                        let target = self.buildTargetObject(e.currentTarget, true);
                        if($(e.currentTarget).hasClass('inactive')) {
                            return;
                        }
                        if (target.events.length > 0) {
                            let comment = "",
                                guestComment = "",
                                title = "",
                                guest = 0;
                            $.each(target.events, function(k,v){
                                if(v.isGuest) {
                                    guest = guest + 1;
                                    guestComment += "Guest " + guest + ": <b>" + v.parkinglot + "</b><hr>";
                                } else {
                                    title = v.admin !== null ? "Blocked by parking admin" : "Reserved by " + v.title;
                                }
                            });
                            comment += guestComment + title;
                            openTooltip(target.element, comment, true);
                        }
                    });

                    $(document).on('mouseleave touchend', '.event', function (e) {
                        let target = self.buildTargetObject(e.currentTarget, true);
                        if($(e.element).hasClass('inactive')) {
                            return;
                        }
                        closeTooltip(target.element);
                    });
                }
            });

            parkingLotCal.setEvents(events);
        },

        getParkingCalendar = function (events, month, weeks) {
            let nextFriday = moment().add(2, 'w').day(moment().day() > 4 ? 5 : -2),
                today18 = moment(new Date()).startOf('day').hour(17).minute(59),
                parkingStart = moment(moduleStart, 'DD.MM.YYYY'),
                now = moment(new Date()),
                diff = now.diff(parkingStart, 'days');

            if(diff < 0) {
                now = parkingStart;
                nextFriday = moment(now).add(2, 'w').day(moment().day() > 4 ? 5 : -2);
            }

            if(today18.isBefore(now, 'hour')) {
                now = now.add(1, 'd');
            }

            let parkingCal = $parkingCal.clndr({
                template: $("#template-parking-calendar").html(),
                startWithMonth: month,
                weekOffset: 1,
                daysOfTheWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                constraints: {
                    startDate: now,
                    endDate: nextFriday
                },
                extras: {
                    options: {
                        show_weekends: false,
                        weekNumbers: weeks,
                    },
                },
                clickEvents: {

                    click: function(target) {
                        if($(target.element).hasClass('inactive') || $(target.element).hasClass('adjacent-month') || $(target.element).hasClass('is-holiday')) {
                            return true;
                        }
                        if ($(target.element).hasClass('event')) {
                            let reservation = $(target.element).data('reservation');
                            openDeleteModal(employee, moment(target.date._i).format('DD.MM.YYYY'), target.events);
                        } else {
                            $.ajax({
                                url: '/parking/get-form',
                                type: 'post',
                                dataType: 'json'
                            }).done(function (data) {
                                $('body').append(data['response']);
                                openModal($('#parkingReservation'), moment(target.date._i).format('DD.MM.YYYY'));
                            });
                        }
                    },

                    onMonthChange: function (month) {
                        this.removeEvents(function (event) {
                            return true;
                        });
                        const calendarParkingEvents = [];
                        $.ajax({
                            method: "post",
                            dataType: "json",
                            async: true,
                            url: '/parking/calendar/get',
                            data: {employee: employee, reservation: isReservation, month: month.startOf('month').format('YYYY-MM-DD')},
                            success: function (data) {
                                $.each(data['data'], function (key, event) {
                                    let item,
                                        occupied = event.occupied === true;

                                    if(key === 'holidays') {
                                        $.each(event, function(k, holiday){
                                            item = {
                                                "date": holiday.date,
                                                "id": holiday.id,
                                                "title": holiday.name,
                                                "comment": holiday.name,
                                                "color": "#f0f0f0",
                                                "isHoliday": holiday.isHoliday
                                            };
                                            calendarParkingEvents.push(item);
                                        });
                                        return true;
                                    }

                                    item = {
                                        "date": event.start,
                                        "title": event.titleHtml,
                                        "parking": event.parkingLot,
                                        "color": event.color,
                                        "id": event.id,
                                        "occupied": occupied,
                                        "processed": event.processed
                                    };
                                    calendarParkingEvents.push(item);
                                });
                                getParkingCalendar(calendarParkingEvents, month);
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                            }
                        });
                    }
                },
                doneRendering: function() {
                    let self = this;

                    $(document).on('mouseover touchstart', '.event', function (e) {
                        let target = self.buildTargetObject(e.currentTarget, true);
                        if($(e.currentTarget).hasClass('inactive')) {
                            return;
                        }
                        if (target.events.length > 0) {
                            let comment = "",
                                guestComment = "",
                                title = "",
                                guest = 0;
                            $.each(target.events, function(k,v){
                                if(v.isGuest) {
                                    guest = guest + 1;
                                    guestComment += "Guest " + guest + ": <b>" + v.parkinglot + "</b><hr>";
                                } else if (isReservation) {
                                    if(v.processed) {
                                        title = v.occupied ? "parking lots are occupied" : v.title;
                                    } else {
                                        title = "reservation not yet processed";
                                    }
                                }
                            });
                            comment += guestComment + title;
                            openTooltip(target.element, comment, true);
                        }
                    });

                    $(document).on('mouseleave touchend', '.event', function (e) {
                        let target = self.buildTargetObject(e.currentTarget, true);
                        closeTooltip(target.element);
                    });
                }
            });

            parkingCal.setEvents(events);
        },

        getParkingCalendarEvents = function (employee, isReservation) {
            const calendarParkingEvents = [];
            let urlMonth = getQueryString('month'),
                date = moment(new Date()),
                start = moment(moduleStart, 'DD.MM.YYYY'),
                month = urlMonth !== undefined && urlMonth !== null ? moment(urlMonth, 'YYYY-MM-DD') : moment(),
                diff = date.diff(start, 'months', true);
            if(diff < 0) {
                month = start;
            }
            if(!isReservation) {
                const today18 = moment(new Date()).startOf('day').hour(17).minute(59);
                if(today18.isBefore(month, 'hour')) {
                    month = month.add(1, 'd');
                }
            }
            $.ajax({
                method: "post",
                dataType: "json",
                async: true,
                url: '/parking/calendar/get',
                data: {employee: employee, reservation: isReservation, month: month.startOf('month').format('YYYY-MM-DD')},
                success: function (data) {
                    if(isReservation) {
                        $.each(data['data'], function (key, event) {
                            let item,
                                occupied = event.occupied === true;

                            if(key === 'holidays') {
                                $.each(event, function(k, holiday){
                                    item = {
                                        "date": holiday.date,
                                        "id": holiday.id,
                                        "title": holiday.name,
                                        "comment": holiday.name,
                                        "color": "#f0f0f0",
                                        "isHoliday": holiday.isHoliday
                                    };
                                    calendarParkingEvents.push(item);
                                });
                                return true;
                            }

                            item = {
                                "date": event.start,
                                "title": event.titleHtml,
                                "parking": event.parkingLot,
                                "parkinglot": event.parkingLot,
                                "color": event.color,
                                "id": event.id,
                                "occupied": occupied,
                                "processed": event.processed,
                                "guest": event.guest,
                                "isGuest": event.isGuest,
                                "comment": event.comment
                            };
                            calendarParkingEvents.push(item);
                        });
                        $.each(data['free'], function(key, event) {
                            let item;
                            if(event === undefined || event.length === 0) {
                                item = {
                                    "date": key,
                                    "parking": 0,
                                    "title": "no free parking lots",
                                    "comment": "no free parking lots",
                                    "color": "#F5A8CC",
                                    "occupied": 1,
                                    "no_free": 1
                                };
                                calendarParkingEvents.push(item);
                            }
                        });

                        const dateObj = month.toDate(),
                            firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1),
                            lastDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0),
                            firstDayWeek = weekNum(firstDay),
                            lastDayWeek = weekNum(lastDay),
                            weeks = range(firstDayWeek, lastDayWeek, 1);

                        $parkingCal.clndr().destroy();
                        getParkingCalendar(calendarParkingEvents, month, weeks);

                    } else {

                        $.each(data['data'], function (key, event) {
                            let item,
                                title = event.employee === null ? (event.comment === null ? '' : event.comment) : event.employee;
                            if(event.parkinglot !== null) {
                                title += "<br>" + event.parkinglot;
                            }

                            if(key === 'holidays') {
                                $.each(event, function(k, holiday){
                                    item = {
                                        "date": holiday.date,
                                        "id": holiday.id,
                                        "title": holiday.name,
                                        "comment": holiday.name,
                                        "color": "#f0f0f0",
                                        "isHoliday": holiday.isHoliday
                                    };
                                    calendarParkingEvents.push(item);
                                });
                                return true;
                            }

                            item = {
                                "date": event.start,
                                "title": title,
                                "color": event.color,
                                "id": event.id,
                                "occupied": event.occupied,
                                "blocked": event.blocked,
                                "guest": event.guest,
                                "isGuest": event.isGuest,
                                "parkinglot": event.parkinglot,
                                "admin": event.admin,
                                "comment": event.comment
                            };
                            calendarParkingEvents.push(item);
                        });
                        const dateObj = month.toDate(),
                            firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1),
                            lastDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0),
                            firstDayWeek = weekNum(firstDay),
                            lastDayWeek = weekNum(lastDay),
                            weeks = range(firstDayWeek, lastDayWeek, 1);

                        $parkingCal.clndr().destroy();
                        getParkingLotCalendar(calendarParkingEvents, month, weeks);

                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                }
            });
        };

    if($parkingCal.length > 0) {

        let employee = $parkingCal.data('employee'),
            isReservation = $parkingCal.data('reservation');

        getParkingCalendarEvents(employee, isReservation);
    }

    if($('.parking-dashboard').length > 0){

        let getNextReservation = function() {

            if($('.card-parking-nr').hasClass('is-myparking')) {
                return true;
            }

            $.ajax({
                url: '/parking/next-reservation',
                type: 'post',
                async: true,
                dataType: 'json'
            }).done(function(data) {
                if(data['success'] === false) {
                    return false;
                }

                let parking = data['data'],
                    $nr = $('.parking-nr'),
                    $text = $('.parking-date');

                if(parking === undefined || parking.length === 0 || parking['startDate'] === undefined) {
                    $text.html("No confirmed reservation in the future");
                    $nr.html("<i class='fal fa-parking-slash'></i>");
                    return true;
                }

                $nr.html(parking['parkingLotNr'] + "/" + parking['parkingLotLevel']);
                $text.html(parking['location'] + ", " + parking['carPark'] + "<br>" + moment(parking['startDate'], 'YYYY-MM-DD').format('DD.MM.YYYY'));
            });
        },

        getNextReservations = function() {
            $.ajax({
                url: '/parking/next-reservations',
                type: 'post',
                async: true,
                dataType: 'json'
            }).done(function(data) {
                if(data['success'] === false || data['data'] === false) {
                    return false;
                }

                let reservations = data['data'],
                    $list = $('.next-reservations-list'),
                    html = '';

                html += "<table class='next-reservation-ul table'>" +
                    "<thead><tr><th scope='col'>Date</th><th scope='col'>Parking lot</th><th>&nbsp;</th></tr></thead><tbody>";
                $.each(reservations, function(k, i) {
                    let date = moment(i['startDate'], 'YYYY-MM-DD').format('DD.MM.YYYY'),
                        guest = i['guest'] >= 1,
                        parkingInfo = guest ? "<span data-toggle='tooltip' data-placement='top' title='" + i['comment'] + "'><i class='fal fa-user-tie'></i> Guest</span>" : "",
                        parkingLot = i['parkingLotId'] === null ? 'not processed' : "<b>" + i['parkingLotNr'] + "/" + i['parkingLotLevel'] + "</b>, " + i['location'] + "/" + i['carPark'],
                        notConfirmedCls = i['parkingLotId'] === null ? 'text-muted' : '';
                    html += "<tr data-id='" + i['id'] + "' class='" + notConfirmedCls + "'>" +
                        "<td>" + date + "</td>" +
                        "<td>" + parkingLot + "</td>" +
                        "<td>" + parkingInfo + "</td>" +
                        "</tr>";
                });
                html += "</tbody></table>";
                $list.html(html);
                $list.find('[data-toggle="tooltip"]').tooltip();
            });
        },

        getNextReservationByParkingLot = function() {
            const $cardParkingNr = $('.card-parking-nr');
            if(!$cardParkingNr.hasClass('is-myparking')) {
                return false;
            }
            const parkingLotId = $cardParkingNr.data('parking-lot-id'),
                $next = $('.next-reservation'),
                $recent = $('.recent-reservations');

            $('.form-error').remove();

            $.ajax({
                url: '/parking/next-reservations-parking-lot',
                method: 'post',
                dataType: 'json',
                data: {parkingLot: parkingLotId},
                async: true
            }).done(function(data) {
                let err = "",
                    html = "",
                    today = moment();
                if(data["success"] === false) {
                    err = "<div class='form-error alert alert-danger'><strong>Error</strong><br>Something went wrong. Please try again later.</div>";
                    $next.html(err);
                    $recent.html(err);
                }
                if(data["success"] === true && data["data"] === false) {
                    err = "<div class='form-error alert alert-light'><strong>No data found</strong><br>No reservations are found for your parking lot.</div>";
                    $next.html(err);
                    $recent.html(err);
                    return false;
                }

                html = "<ul class='list-group list-group-flush'>";
                $.each(data["data"], function(k, v){
                    const date = moment(v['date'], 'DD.MM.YYYY'),
                        admin = v['admin'] === null ? '' : '<i class="fal fa-lock"></i> Blocked by admin',
                        guest = v['guest'] >= 1,
                        guestInfo = guest ? "<span data-toggle='tooltip' data-placement='top' title='" + v['comment'] + "'><i class='fal fa-user-tie'></i> Guest (reserved by " + v['employee'] + ")</span>" : "",
                        employee = v['employee'] === null || guest ? '' : v['employee'];
                    if(k === 0) {
                        const diff = today.diff(date, 'd');
                        if(diff <= 0) {
                            let next = "<div class='date'><b>" + v['date'] + "</b></div>" +
                                "<div class='employee'>" +
                                    "<i>" + admin + employee + guestInfo + "</i>" +
                                "</div>";
                            $next.html(next);
                            $next.find("[data-toggle='tooltip']").tooltip();
                            return true;
                        } else {
                            $next.closest('.card').remove();
                        }
                    }

                    html += "<li class='list-group-item px-0 text-muted'>" + v['date'] + "<br>" +
                        "<i>" + admin + employee + guestInfo + "</i></li>";

                });
                html += "</ul>";
                $recent.html(html);
                $recent.find("[data-toggle='tooltip']").tooltip();
            });
        };

        getNextReservation();
        getNextReservations();
        getNextReservationByParkingLot();

        $(document).on('click', '.new-parking-reservation', function(e) {
            e.preventDefault();
            const hasParkingLot = $('body').hasClass('has-parkinglot');
            let data = {};
            if(hasParkingLot) {
                data["onlyGuest"] = true;
            }
            $.ajax({
                url: '/parking/get-form',
                type: 'post',
                dataType: 'json',
                data: data
            }).done(function(data){
                $('body').append(data['response']);
                openModal($('#parkingReservation'));
            });
        });
    }

    // add new reservation or guest reservation
    $(document).on('click', '#parking_reservation_add, #guest_reservation_add', function(e){
        e.preventDefault();
        const $this = $(this),
            $form = $this.closest('form'),
            isGuest = $this.attr('id') === 'guest_reservation_add',
            errHtml = isGuest ? checkFormInput($form, true) : checkFormInput($form);

        $('.form-error').empty();
        if(errHtml.length > 0){
            $form.find('.form-error').html("<div class='alert alert-danger' role='alert'>" + errHtml + "</div>");
            return false;
        }

        $this.attr("disabled",true);
        $.ajax({
            url: '/parking/add',
            type: 'post',
            data: $form.serialize()
        }).done(function(data){

            $this.attr("disabled",false);
            if(data['success'] === false || data['status'] !== 'saved'){
                let errorMsg = data['data'].length > 0 ? data['data'] : 'Saving failed!';
                $(".modal").find('.form-error').html("<div class='alert-danger'>" + errorMsg + "</div>");
                return false;
            }

            const parking = data['pool'],
                exists = data['exists'],
                start = isGuest ? $form.find('#guest_reservation_startDate').val() : $form.find('#parking_reservation_startDate').val(),
                end = isGuest ? '' : $form.find('#parking_reservation_endDate').val();
            let dateInfo = start,
                info = '',
                errInfo = '',
                existsInfo = '';

            dateInfo += end.length === 10 ? ' &mdash; ' + end : '';
            if(exists.length > 0) {
                existsInfo += "<p class='text-muted'>Following reservations already exists<br>";
                $.each(exists, function(k, v){
                    existsInfo += v;
                    if((k + 1) < exists.length) {
                        existsInfo += ', ';
                    }
                });
                existsInfo += "</p><hr class='mb-3'>";
            }

            if(isGuest) {
                console.log(data);
                const err = data['data'] !== undefined ? (data['data']['err'] !== undefined ? data['data']['err'] : undefined) : undefined,
                    ok = data['data'] !== undefined ? (data['data']['ok'] !== undefined ? data['data']['ok'] : undefined) : undefined;
                if(err !== undefined && err.length > 0) {
                    $.each(err, function(k,v){
                        errInfo += "<span class='text-danger'>" + v + "</span><br>";
                    });
                }
                info += "<p><em>Your guest reservation is saved.<br>Date: " + dateInfo + "</em></p><hr class='mb-3'>" + existsInfo + errInfo +
                    "<hr class='mb-3'><a href='#' class='btn btn-light grey close-reservation-modal'>Close</a>";
            } else {
                info += "<p><em>Your reservations are saved.<br>Date: " + dateInfo + "</em></p><hr class='mb-3'>" + existsInfo +
                    "<p class='text-muted'><em>Every day at 6 pm you will be informed about your parking reservation for the following day (availability and place).<br><br><strong>Please note that the reservation can change at any time.</strong></em></p><hr class='mb-3'>" +
                    "<a href='#' class='btn btn-light grey close-reservation-modal'>Close</a>";
            }
            $("#parkingReservation").find('.modal-body').html(info);

            if($parkingCal.length > 0) {
                let employee = $parkingCal.data('employee'),
                    isReservation = $parkingCal.data('reservation');
                getParkingCalendarEvents(employee, isReservation);
            }
        });

    });

    // Delete reservation
    $(document).on('click', '.yes-delete-reservation', function(e){
        const $this = $(this),
            $modal = $this.closest('.modal'),
            $modalBody = $modal.find('.modal-body'),
            $reservations = $modal.find('.reservation-list'),
            $reservation = $this.closest('.reservation'),
            reservation = $this.data('reservation'),
            date = $modal.data('date'),
            employee = $this.data('employee');

        $('.form-error').remove();
        $.ajax({
            url: '/parking/delete',
            type: 'post',
            dataType: 'json',
            data: {employee: employee, reservation: reservation}
        }).done(function(data){
            if(data['success'] === false || data['data'] === false) {
                let errorMsg = data['data'].length > 0 && data['data'] !== false ? data['data'] : 'Deleting failed!';
                $modalBody.prepend("<div class='form-error alert alert-danger' role='alert'><i class='fal fa-exclamation-triangle'></i> " + errorMsg + "</div>");
                return false;
            }
            $reservation.remove();
            getParkingCalendarEvents(employee, isReservation);
            const countGuest = $reservations.find('.is-guest').length,
                hasOwn = $reservations.find('.is-own').length > 0;
            $modal.find('#guest_reservation').remove();
            $modal.find('#parking_reservation').remove();
            $modal.find('.add-guest-reservation-form').remove();
            $modal.find('.add-parking-reservation-form').remove();
            const html = getGuestReservationForm(date, employee, countGuest, hasOwn);
            $reservations.append(html);
        });
    });

    // Close modal
    $(document).on('click', '.modal .close, .close-reservation-modal, .close-modal', function(e) {
        e.preventDefault();
        closeModal();
        $('#parkingReservation').remove();
    });


    // handle guest reservation in delete modal
    $(document).on('click', '.add-guest-reservation-form', function(e){
        e.preventDefault();
        $('#guest_reservation').removeClass('d-none');
        $(this).addClass('d-none');
        $('.add-parking-reservation-form').addClass('d-none');
    });
    $(document).on('click', '#cancel_guest_reservation', function(e){
        e.preventDefault();
        $('#guest_reservation').addClass('d-none');
        $('.add-guest-reservation-form, .add-parking-reservation-form').removeClass('d-none');
    });
    $(document).on('click', '.add-parking-reservation-form', function(e){
        e.preventDefault();
        $('#parking_reservation').removeClass('d-none');
        $(this).addClass('d-none');
        $('.add-guest-reservation-form').addClass('d-none');
    });
    $(document).on('click', '#cancel_parking_reservation', function(e){
        e.preventDefault();
        $('#parking_reservation').addClass('d-none');
        $('.add-guest-reservation-form, .add-parking-reservation-form').removeClass('d-none');
    });

    $(document).on('click', '#save_guest_reservation, #save_parking_reservation', function(e){
        e.preventDefault();
        const $form = $(this).closest('form'),
            $modal = $form.closest('.modal'),
            date = $modal.data('date'),
            data = $form.serialize();
        $form.find('.form-error').remove();

        $.ajax({
            url: "/parking/add",
            data: data,
            type: 'post',
            dataType: 'json'
        }).done(function (data){
            if(data['success'] === false) {
                const msg = data['data'] !== undefined ? data['data'] : '';
                $form.prepend("<div class='row form-error'><div class='col-12 alert alert-danger'><strong>Saving failed</strong><br>" + msg + "</div></div>")
                return false;
            }
            $('#guest_reservation, #parking_reservation').remove();
            $('.add-guest-reservation-form, .add-parking-reservation-form').remove();

            getParkingCalendarEvents(employee, isReservation);
            getReservationsByDate(date, employee, $('#deleteReservation'));

        });
    });
});
